import { find } from 'lodash';
import { createSelector } from 'reselect';
import { getAppData } from './app-data-selectors';
import { getCategories } from './categories-selectors';
import { getCurrentMatchPathname } from '../router/router-selectors';
import * as getHeaderLinksService from '../services/get-header-links';

export const getHeaderLinks = createSelector(getCategories, getAppData, (categories, appData) =>
  getHeaderLinksService.getHeaderLinks(categories, appData),
);

export const getActiveHeaderLink = createSelector(
  getHeaderLinks,
  getCurrentMatchPathname,
  (links, currentPath) => find(links, link => link.path === currentPath) || links[0],
);
