import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { getIsAppBundle, getIsPostPageBundle } from '../../store/app-config/app-config-selectors';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';
import styles from './desktop-header.scss';
import { connect } from '../runtime-context';

const renderMenu = menu => {
  return menu && <div className={styles.menu}>{menu}</div>;
};

const DesktopHeader = ({
  left,
  right,
  menu,
  className,
  createdWithResponsiveEditor,
  isAppBundle,
  isPostPageBundle,
}) => {
  const containerClassName = classNames(styles.container, className, 'blog-header-background-color');
  const wrapperClassName = classNames(
    styles.header,
    (isAppBundle || (isPostPageBundle && createdWithResponsiveEditor)) && styles.withPadding,
  );
  return (
    <div className={containerClassName} data-hook="blog-desktop-header-container">
      <div className={wrapperClassName}>
        {left}
        {!createdWithResponsiveEditor && right}
      </div>
      {renderMenu(menu)}
    </div>
  );
};

DesktopHeader.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  menu: PropTypes.node,
  className: PropTypes.string,
  createdWithResponsiveEditor: PropTypes.bool.isRequired,
  isAppBundle: PropTypes.bool.isRequired,
  isPostPageBundle: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = state => ({
  createdWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
  isAppBundle: getIsAppBundle(state),
  isPostPageBundle: getIsPostPageBundle(state),
});

export default connect(mapRuntimeToProps)(DesktopHeader);
