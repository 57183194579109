export const FEED_WIDGET_ID_PROD = '14c1462a-97f2-9f6a-7bb7-f5541f23caa6';
export const FEED_WIDGET_NAME = 'feed';
export const POST_WIDGET_ID_PROD = '211b5287-14e2-4690-bb71-525908938c81';
export const POST_WIDGET_NAME = 'post-page';
export const CATEGORY_MENU_WIDGET_ID = 'a0d7808c-0d7d-4a40-8cf0-911a9f0de96f';
export const CATEGORY_MENU_WIDGET_NAME = 'category-menu';

const blogAppDefId = '14bcded7-0066-7c35-14d7-466cb3f09103';
export const POST_LIST_WIDGET_TYPE = `${blogAppDefId}-arqe5`;
export const ARCHIVE_LIST_WIDGET_TYPE = `${blogAppDefId}-d94yd`;
export const CATEGORY_LIST_WIDGET_TYPE = `${blogAppDefId}-otga2`;
export const POST_TICKER_WIDGET_TYPE = `${blogAppDefId}-qlgsh`;
