export const getHeaderLinks = (categories, appData) =>
  categories.reduce(
    (acc, category) => {
      acc.push({
        key: category._id,
        path: `/categories/${category.slug}`,
        text: category.menuLabel,
      });
      return acc;
    },
    [
      {
        key: 'latest_posts',
        path: '/',
        text: appData.menuLabel,
      },
    ],
  );
